// JavaScript Document

// Scripts written by __gulp_init_author_name__ @ __gulp_init_author_company__

import Swiper from "swiper";

// init swiper
if (document.querySelectorAll(".swiper-container--hero .swiper-slide").length > 1) {
    new Swiper(".swiper-container--hero", {
        autoplay: {
            delay: 5000,
        },
        loop: true,
        speed: 150,
    });
}
