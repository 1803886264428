// JavaScript Document

// vendor

import jQuery from "jquery";
import "@popperjs/core";
import "bootstrap";

// custom

jQuery(document).ready(function () {
    jQuery(".menu-toggle").on("click", function () {
        jQuery("body").toggleClass("menu-open");
    });

    jQuery(document).on("click", function () {
        jQuery("body").removeClass("menu-open");
    });
    jQuery(".main-navigation, .menu-toggle").on("click", function (e) {
        e.stopPropagation();
    });

    jQuery(".nf-before-form-content").hide();

});

function blogs_more_posts($html) {

    let str = "action=get_blogs_more_posts";
    const category = document.getElementById("offset").dataset.category;
    const offset = jQuery("#offset").val();
    if (offset != "" && offset != 0) {
        str += `&offset=${offset}`;
    }

    if (category !== "" && category) {
        str += `&cat=${category}`;
    }

    console.log(category);

    jQuery.ajax({
        url: l10n.post_list_admin_URL_NAME.admin_URL,
        type: "POST",
        dataType: "JSON",
        data: str,
        beforeSend() {
            if ($html == "html") {
                jQuery("#html_loader").addClass("html_loader");
                jQuery(".main-loader").show();
                jQuery(".blogloadmore").hide();
            } else {
                jQuery("#loader").addClass("loader");
            }
        },
        success(data) {
            jQuery("#html_loader").removeClass("html_loader");
            jQuery("#loader").removeClass("loader");
            jQuery(".blogloadmore").show();
            jQuery(".main-loader").hide();
            const responseData = JSON.parse(JSON.stringify(data));
            if (responseData.result == "") {
                jQuery("#appenddata").html("<h4 class=\"not-found-title\"> No posts found.</h4>");
            } else {
                if ($html == "html") {
                    if (responseData.result) {
                        jQuery("#appenddata").html(responseData.result);

                    } else {
                        jQuery("#appenddata").html("<p class=\"text-center mx-auto\">No Blogs found</p>");
                    }
                } else {
                    jQuery("#appenddata").append(responseData.result);
                }
            }
            jQuery("#offset").val(responseData.offset);
            console.log(`${responseData.total_post}>=${responseData.offset}`);
            if (responseData.total_post <= responseData.offset) {
                jQuery(".loadmore").hide();
            } else {
                jQuery(".loadmore").show();
            }
        }, error(err) {
            console.log(err);
        }
    });
}

/* Project Partners post load more ajax*/
jQuery(document).ready(function () {

    /* Project Partners post load more action*/
    jQuery(".blogloadmore").on("click", function () {
        const $html = "append";
        blogs_more_posts($html);
    });

});
